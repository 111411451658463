import { useQuery } from 'react-query';
import { useAuth } from '../components/navigation/Authentication.jsx';
import logError from './metadata/ErrorLogging';

export function useAuthenticatedQuery(queryKey, queryFn, scopes = ["openid"], config = {}) {
    const { acquireTokenSilent, isInitialised } = useAuth();
    
    const enhancedQueryFn = async () => {

        if (!isInitialised) {
            throw new Error('MSAL instance not initialised');
        }

        try {
          const tokenResponse = await acquireTokenSilent(scopes);
          return await queryFn(tokenResponse);
        } catch (error) {
          logError('Auth query failed',error,{},false);
          throw error;
        }
    };

    const finalEnabled = ((config.enabled !== undefined ? config.enabled : true) && isInitialised);
  
    const { data, isLoading, refetch, error } = useQuery(queryKey, enhancedQueryFn, {
        ...config,
        retry: 3, // Retry 3 times before failing
        enabled: finalEnabled,
    });

    return { data, isLoading, refetch, error, isWaiting: !isInitialised };
  }
  