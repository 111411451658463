/**
 * @typedef {import('../../types/apiUpsertResponse.types.js').ApiResponse} ApiResponse
 */
import { useQueryClient } from 'react-query';
import useAuthenticatedMutation from '../useAuthenticatedMutation';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config';
import logError from '../../metadata/ErrorLogging';

/**
 * Performs the API call to update a user notification.
 *
 * @param {{ data: Object, method: string }} params - The payload and HTTP method.
 * @param {string} token - The authentication token provided by useAuthenticatedMutation.
 * @returns {Promise<ApiResponse>} The API response, either a success or error object.
 */
const updateNotificationStatus = async ({ data, method }, token) => {
  const url = `${API_BASE_URL}disqoadmin/user_notification`;
  try {
    const response = await axios({
      method,
      url,
      headers: {
        'Authorization': `Bearer ${token}`, // attach the token for authentication
        'x-functions-key': FUNCTION_KEY,
      },
      data: data,
    });
    return response.data;
  } catch (error) {
    logError('Failed to update user notification:', error, {});
    throw error;
  }
};

 /**
 * @typedef {Object} UserNotificationManagerReturn
 * @property {(payload: Object, method?: string) => Promise<Object>} performAction - Function that triggers a notification update.
 * @property {boolean} isLoading - Flag indicating if the mutation is in progress.
 * @property {boolean} isError - Flag indicating if there was an error.
 * @property {Error|null} userNotificationError - Holds the error, if one exists.
 * @property {any} data - The data returned from the mutation.
 * @property {Function} reset - Function to reset the mutation state.
 */

 /**
 * Hook to manage user notifications with optimistic updates.
 *
 * @param {function} onSuccess - Callback invoked on a successful mutation.
 * @param {function} onError - Callback invoked on a failed mutation.
 * */
export default function useUserNotificationManager(onSuccess, onError) {
  const queryClient = useQueryClient();

  // Create the mutation with optimistic updates using useAuthenticatedMutation.
  const mutation = useAuthenticatedMutation(updateNotificationStatus, [PROJECT_SCOPE], {
    // onMutate runs before the mutation is fired.
    onMutate: async (newPayload) => {
      // Derive a project-specific query key.
      const queryKey = ['user_notifications', newPayload.data.project_id];
      
      // Cancel any outgoing refetches for this project.
      await queryClient.cancelQueries(queryKey);
      
      // Snapshot the previous notifications.
      const previousNotifications = queryClient.getQueryData(queryKey);
      
      // Optimistically update the cache: update the notification's active status.
      queryClient.setQueryData(queryKey, (old) => {
        if (!old) return old;
        return old.map((notif) =>
          notif.id === newPayload.data.notification_id
            ? { ...notif, active: newPayload.data.active }
            : notif
        );
      });
      
      // Return context with the previous data and query key for rollback.
      return { previousNotifications, queryKey };
    },
    // If the mutation fails, roll back to the previous state.
    onError: (err, context) => {
      queryClient.setQueryData(context.queryKey, context.previousNotifications);
      if (onError) onError(err);
    },
    // Call onSuccess if provided.
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    // Invalidate the query so fresh data is fetched after success or error.
    onSettled: (context) => {
      queryClient.invalidateQueries(context.queryKey);
    },
  });

  // Wrap the mutation's mutate function as performAction.
  const performAction = (payload, method = 'PUT') => {
    return new Promise((resolve, reject) => {
      mutation.mutate(
        { data: payload, method },
        {
          onSuccess: (result) => {
            resolve(result);
            if (onSuccess) onSuccess(result);
          },
          onError: (error) => {
            // Ensure the rejection reason is an Error instance.
            const err = error instanceof Error ? error : new Error(String(error));
            reject(err);
            if (onError) onError(err);
          },
        }
      );
    });
  };

  // Return the mutation object along with our performAction wrapper.
  return { ...mutation, performAction };
}