import { useAuthenticatedQuery } from './useAuthenticatedQuery';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../config';
import { useAuth } from '../components/navigation/Authentication.jsx';
import logError from './metadata/ErrorLogging';

/**
 * Custom data hook to fetch user details from the API.
 * Uses authenticated query to ensure the user has permission to call the API.
 *
 * @returns {{
 *   data: Object | undefined,
 *   isLoading: boolean,
 *   refetch: Function,
 *   error: Error | null
 * }} The user data and related information.
 */
// Custom data hook to fetch user details from the API
export default function useUser() {
  // Get the authenticated user from the useAuth hook
  const { user: authUser } = useAuth();

  // Function to fetch user details from the API
  const fetchUserDetails = async (token) => {
    try {
      // Make a GET request to the API endpoint with the user token and function key
      const response = await axios.get(`${API_BASE_URL}user_details`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        },
      });
      // Return the response data
      return response.data;
    } catch (error) {
      // Log and throw any errors that occur during the API call
      logError('Failed to load user details in useUser', error, {});
      throw error;
    }
  };

  // Define the user scope for the authenticated query
  const userScope = [PROJECT_SCOPE];

  // Use the useAuthenticatedQuery hook to fetch user data from the API
  const { data: dbUserData, isLoading, refetch, error } = useAuthenticatedQuery(`user`, fetchUserDetails, userScope, {
    staleTime: 5 * 60 * 1000, //Milliseconds before the data is stale and should be refetched: 5 minutes (300,000 milliseconds)
    enabled: !!authUser?.localAccountId,  //Only fetch data if the user is authenticated
  });

  // Return the combined user data, loading state, and refetch function
  return { user: dbUserData, isLoading, refetch, error};
}
