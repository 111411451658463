// UserNotificationsDialog.jsx
import React, { useState, useEffect } from 'react';
import { Dialog, Button, Switch } from '@material-tailwind/react';
import { FaBell } from 'react-icons/fa';
import { Select, SelectItem } from "@tremor/react";

import AlertManager from '../../general/AlertManager.jsx';
import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import useUserNotifications from '@datahooks/admin/user/useUserNotifications.js';
import useUserNotificationManager from '@datahooks/admin/user/useUserNotificationManager.js';

import useProjects from '@datahooks/useProjects';

import logError from '@datahooks/metadata/ErrorLogging.js';

const UserNotificationsDialog = () => {
  // Local state for dialog visibility and feedback messages.
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  //Fetch user projects
  const { projects, isLoading: projectsLoading } = useProjects();

  // Set a default project when projects are loaded.
  useEffect(() => {
    if (!projectsLoading && projects && projects.length > 0 && !selectedProjectId) {
      setSelectedProjectId(projects[0].projectId);
    }
  }, [projects, projectsLoading, selectedProjectId]);

  // Fetch notifications data specific to the project. Will return [] while selectedProjectId is null.
  const {
    userNotificationsData,
    userNotificationsLoading,
    userNotificationsError,
  } = useUserNotifications(selectedProjectId, isOpen);

  // Callbacks for successful or failed notification updates.
  const userNotificationUpdated = () => {
    setSuccessMessage("Notification updated successfully.");
  };

  const userNotificationSaveFailed = (error) => {
    const errorMessage = error?.response?.data?.errorResponse?.message 
                     || error?.response?.data?.message 
                     || error?.message 
                     || 'Unknown error';
    setErrorMessage(`Failed to update notification: ${errorMessage}`);
  };

  // Hook to manage notification updates using optimistic updates.
  const { performAction } = useUserNotificationManager(
    userNotificationUpdated,
    userNotificationSaveFailed
  );

  // Toggle the dialog open/closed.
  const handleToggle = () => setIsOpen(!isOpen);

  // Handle toggling a notification on or off.
  const handleToggleNotification = (notification) => {
    // Determine the new active status.
    const newStatus = notification.active === 'active' ? 'inactive' : 'active';
    const payload = {
      project_id: selectedProjectId,
      notification_id: notification.notification_id,
      active: newStatus,
      channel: notification.default_channel,
    };

    // Call performAction from the notification manager hook.
    performAction(payload)
      .catch((error) => {
        logError("Error updating notification:", error);
      });
  };

  const handleProjectChange = (projectId) => {
    setSelectedProjectId(projectId);
  };

  return (
    <>
      <Button
        data-testid="user-notifications-button"
        size="sm"
        variant="outlined"
        className="flex text-blue-500 items-center gap-3"
        onClick={handleToggle}
      >
        <FaBell data-testid="unb-icon" size={20} /> Notifications
      </Button>

      <Dialog data-testid="user-notifications-dialogue" open={isOpen} size="lg" handler={handleToggle} dismissible={false}>
        <div className="p-4">
          <h3 className="text-lg font-bold mb-8" data-testid="und-title">User Notifications</h3>

          <Select
              data-testid="und-project-select"
              className="mt-Select-button mb-4"
              label="Project"
              onChange={(e) => handleProjectChange(e)}
              value={selectedProjectId}
            >
              {projects?.map((project) => (
                <SelectItem
                  className="mt-Select-option"
                  key={project.projectId} 
                  value={project.projectId}
                >
                  {project.title}
                </SelectItem>
              ))}
            </Select>

          {(userNotificationsLoading && isOpen) && <LoadingIcon showText />}
          {(userNotificationsError && isOpen) && <div>Error: {userNotificationsError.message}</div>}

          {userNotificationsData && userNotificationsData.length > 0 ? (
            userNotificationsData.map((notification) => (
              <div data-testid={`und-notification-container${notification.id}`} key={notification.id} className="flex justify-between items-center py-2 border-b">
                <div>
                  <div data-testid="und-nc-title" className="font-bold">{notification.name}</div>
                  <div data-testid="und-nc-description" className="text-sm text-gray-600">{notification.description}</div>
                </div>
                <Switch data-testid="und-nc-switch"
                  checked={notification.active === 'active'}
                  color="green"
                  onChange={() => handleToggleNotification(notification)}
                />
              </div>
            ))
          ) : (
              <div data-testid="und-no-notifications">No notifications available.</div>
          )}
          <AlertManager
            data-testid="und-alert-manager"
            className="m-2"
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </div>
        <Dialog.Footer data-testid="und-footer">
          <Button data-testid="undf-close-button" color="pink" onClick={handleToggle}>
            Close
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default UserNotificationsDialog;