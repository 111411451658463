import { useAuthenticatedQuery } from '../useAuthenticatedQuery';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FUNCTION_KEY, PROJECT_SCOPE } from '../../config';
import { useAuth } from '../../components/navigation/Authentication.jsx';
import logError from '../metadata/ErrorLogging';

export default function useSwaggerSpec(apiBaseUrl, endpoint = 'swagger_spec') {
  const { user: authUser } = useAuth();
  const userId = authUser?.idTokenClaims?.extension_DatabaseUserID;

  const fetchSwaggerSpec = async (token) => {
    const url = `${apiBaseUrl}${endpoint}`;

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY
            }
        });
        return response.data;
    } catch (error) {
        logError('Failed to load Swagger spec', error, { url: url });
        throw error;
    }
  };

  // Concatenate orgId to ensure uniqueness of the query key per organisation
  const queryKey = `swaggerSpec_${userId}_${apiBaseUrl}`;

  const projectScope = [PROJECT_SCOPE];
  const { data: swaggerSpec = {}, isLoading, refetch, error } = useAuthenticatedQuery(queryKey, fetchSwaggerSpec, projectScope, {
    staleTime: 60 * 60 * 1000, // set stale time to 1 hour
    enabled: !!authUser?.localAccountId
  });

  return { swaggerSpec, isLoading, refetch, error };
}

useSwaggerSpec.propTypes = {
  apiBaseUrl: PropTypes.string,
  endpoint: PropTypes.string
};