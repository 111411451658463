import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { TextInput, Textarea } from "@tremor/react";
import { Typography, Button, Navbar } from '@material-tailwind/react';
import { FaSave, FaBan } from 'react-icons/fa';
import AlertManager from '../../general/AlertManager.jsx';
import * as yup from 'yup';
import _ from 'lodash';
import ImageUploader from '../general/ImageUploader.jsx';
import ProjectTestTypesDialog from './ProjectTestTypesDialog.jsx';
import ProjectNotificationsDialog from './ProjectNotificationsDialog.jsx';
import useProject from '../../../datahooks/admin/project/useProject.js';
import useProjectManager from '../../../datahooks/admin/project/useProjectManager.js';
import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard.jsx';
import { NOTIFICATIONS_ENABLED } from '@home/config';
import { IMAGE_ENTITY_TYPES } from '@datahooks/metadata/ImageValidation.js';

// Define validation schema for the form
const projectSchema = yup.object().shape({
  title: yup.string().required('Please enter a project title.').max(255),
  short_name: yup.string().required('Please enter a short name.').max(50),
  intro_text: yup.string().optional().max(1000),
  released_into_test: yup.date().nullable(),
  target_to_live: yup.date().nullable(),
});

const ProjectEditForm = ({ projectId, addingProject, onCancel, onUpdatedProject, organisationId }) => {
    const imageUploaderRef = useRef(null);
    const [imageSelected, setImageSelected] = useState(false);
    
    const notificationsEnabled = NOTIFICATIONS_ENABLED === 'true'

    const projectUpdated = () => {
        setSuccessMessage("Project updated successfully."); 
        
        if (addingProject) {
            formik.resetForm();
        } else {
            if (imageSelected) { 
                setImageSelected(false); 
            };
            refetchProject();
        }

        (onUpdatedProject && typeof onUpdatedProject === 'function' && onUpdatedProject()); // Call the callback function if provided
    }

    const projectSaveFailed = (error) => {
        setErrorMessage(`Failed to save project: ${error?.errorResponse?.message || 'Unknown error'}`);
    }

    const { performAction } = useProjectManager(projectUpdated, projectSaveFailed);

    const { projectData, loadingProject, refetchProject, projectError } = useProject(projectId);
    
    const [initialValues, setInitialValues] = useState({
        title: '',
        short_name: '',
        intro_text: '',
        released_into_test: null,
        target_to_live: null,
        logo_location: null,
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const getDefaultInitialValues = () => ({
            title: '',
            short_name: '',
            intro_text: '',
            released_into_test: null,
            target_to_live: null,
            logo_location: null,
        });

        if (addingProject) {
            const newInitialValues = getDefaultInitialValues();

            if (!_.isEqual(initialValues, newInitialValues)) {
                setInitialValues(newInitialValues);
            }
        } else if (projectData) {
            const newInitialValues = {
                title: projectData.title || '',
                short_name: projectData.short_name || '',
                intro_text: projectData.intro_text || '',
                released_into_test: projectData.released_into_test || null,
                target_to_live: projectData.target_to_live || null,
                logo_location: projectData.logo_location || null,
            };

            if (!_.isEqual(initialValues, newInitialValues)) {
                setInitialValues(newInitialValues);
                imageUploaderRef?.current?.resetImage();
            }
        }
    }, [addingProject, projectData]);

    const handleSubmit = async (values) => {
        try {
            await projectSchema.validate(values, { abortEarly: false });

            if (imageSelected) {
                imageUploaderRef.current.uploadImage();
            } else {
                await doSave();
            }
        } catch (error) {
            const validationErrors = error.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            formik.setErrors(validationErrors);
        }
    };

    const doSave = async (imagePath = null) => {
        if (addingProject) {
            if (!formik.values.title || !formik.values.short_name) {
                console.error("Title and short name are required.");
                return;
            }
            await doAddProject(imagePath);
        } else {
            await doUpdateProject(imagePath);
        }
    };
    
    const doAddProject = async (imagePath = null) => {
        const payload = {
            new_org_id: organisationId,
            new_title: formik.values.title,
            new_short_name: formik.values.short_name
        };
    
        if (formik.values.intro_text) {
            payload.new_intro_text = formik.values.intro_text;
        }
    
        if (imagePath) {
            payload.new_logo_location = imagePath;
        }
    
        if (formik.values.released_into_test) {
            payload.new_released_into_test = formik.values.released_into_test;
        }
    
        if (formik.values.target_to_live) {
            payload.new_target_to_live = formik.values.target_to_live;
        }
    
        return performAction(payload, 'PUT'); 
    }
    
    const doUpdateProject = async (imagePath = null) => {
        const updatePayload = { project_id: projectId };
    
        if (!_.isEqual(initialValues.title, formik.values.title)) {
            updatePayload.new_title = formik.values.title;
        }
    
        if (!_.isEqual(initialValues.short_name, formik.values.short_name)) {
            updatePayload.new_short_name = formik.values.short_name;
        }
    
        if (imagePath && initialValues.new_logo_location !== imagePath) {
            updatePayload.new_logo_location = imagePath;
        }
    
        if (!_.isEqual(initialValues.intro_text, formik.values.intro_text)) {
            updatePayload.new_intro_text = formik.values.intro_text;
        }
    
        if (!_.isEqual(initialValues.released_into_test, formik.values.released_into_test)) {
            updatePayload.new_released_into_test = formik.values.released_into_test;
        }
    
        if (!_.isEqual(initialValues.target_to_live, formik.values.target_to_live)) {
            updatePayload.new_target_to_live = formik.values.target_to_live;
        }
    
        if (Object.keys(updatePayload).length > 1) {
            return performAction(updatePayload, 'POST'); 
        }
    }

    const handleImageSelected = (file) => {
        setImageSelected(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: projectSchema,
        enableReinitialize: true, 
        onSubmit: handleSubmit, 
    });

    if (loadingProject) {
        return <LoadingIcon showText />;
    }

    if (projectError) {
        return <div><ErrorCard cardType={CARD_TYPES.EMPTY} /> {projectError.message}</div>; 
    }

    return (
        <>
            <Typography variant="h5" className="text-blue-500 mt-2">
                {addingProject ? 'Add New Project' : 'Edit Project'}
            </Typography>

            <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center w-full max-w-4xl">
                {projectId !== -1 && !addingProject && projectData && (
                    <Typography variant="h5" className="text-blue-500 mt-4">
                        Project ID: {projectId}
                    </Typography>
                )}

                <Typography variant="h5" className="text-blue-500 mt-4">Title</Typography>
                <TextInput
                    label="Title"
                    name="title"
                    placeholder="Enter the full title of the project"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && formik.errors.title}
                    errorMessage={formik.errors.title}
                />

                <Typography variant="h5" className="text-blue-500 mt-4">Short name</Typography>
                <TextInput
                    label="Short Name"
                    name="short_name"
                    placeholder="Enter a short name for the project for use in the menu"
                    value={formik.values.short_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.short_name && formik.errors.short_name}
                    errorMessage={formik.errors.short_name}
                />

                {projectId !== -1 && !addingProject && projectData && (        
                    <>
                        <Typography variant="h5" className="text-blue-500 mt-4">Project Logo:</Typography>
                        <ImageUploader
                            ref={imageUploaderRef}
                            alt={formik.values.short_name + " logo"}
                            imgSrc={formik.values.logo_location || ''}
                            onSavePath={(path) => doSave(path)}
                            imageType={IMAGE_ENTITY_TYPES.project}
                            imageId={projectId}
                            onImageSelect={handleImageSelected}
                            displayType='logo'
                        />
                    </>
                )}

                <Typography variant="h5" className="text-blue-500 mt-4">Intro</Typography>
                <Textarea
                    label="Intro Text"
                    placeholder="Enter a brief introduction to the project."
                    name="intro_text"
                    autoHeight={true}
                    value={formik.values.intro_text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.intro_text && formik.errors.intro_text}
                    errorMessage={formik.errors.intro_text}
                />

                <Typography variant="h5" className="text-blue-500 mt-4">Released Into Test</Typography>
                <TextInput
                    label="Released Into Test"
                    placeholder="Select a date and time"
                    name="released_into_test"
                    type="date"
                    value={formik.values.released_into_test || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <Typography variant="h5" className="text-blue-500 mt-4">Target to Live</Typography>
                <TextInput
                    label="Target to Live"
                    className='mb-4'
                    name="target_to_live"
                    type="date"
                    value={formik.values.target_to_live || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <AlertManager 
                    successMessage={successMessage} 
                    setSuccessMessage={setSuccessMessage} 
                    errorMessage={errorMessage} 
                    setErrorMessage={setErrorMessage} 
                />

                <Navbar className="m-0 p-0 mt-5 flex items-center justify-end w-full p-4 gap-4 bg-gray-100 border-t border-gray-300">
                    {projectId !== -1 && !addingProject && (    
                        <div className="flex justify-start gap-2">
                            <ProjectTestTypesDialog 
                                projectId={projectId}
                            />
                            {notificationsEnabled && (
                                <ProjectNotificationsDialog
                                    projectId={projectId}
                                />
                            )}
                        </div>
                    )}
                    
                    <div className="flex ml-auto gap-4">
                        <Button 
                            color="blue" 
                            variant="outlined" 
                            onClick={onCancel}
                            className="flex text-blue-500 items-center gap-3"
                        >
                            <FaBan /> Cancel
                        </Button>
                        <Button 
                            color="blue" 
                            type="submit" 
                            disabled={!formik.dirty && !formik.isSubmitting && !imageSelected}
                            className="flex items-center gap-3">
                            <FaSave /> Save
                        </Button>
                    </div>
                </Navbar>
            </form>

        </>
    );
};

ProjectEditForm.propTypes = {
    projectId: PropTypes.number,
    addingProject: PropTypes.bool,
    onCancel: PropTypes.func,
    onUpdatedProject: PropTypes.func,
    organisationId: PropTypes.number,
};
export default ProjectEditForm;