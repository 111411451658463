// ProjectNotificationsDialog.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Switch } from '@material-tailwind/react';
import { FaBell } from 'react-icons/fa';

import AlertManager from '../../general/AlertManager.jsx';
import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import useProjectNotifications from '../../../datahooks/admin/project/useProjectNotifications';
import useProjectNotificationManager from '../../../datahooks/admin/project/useProjectNotificationManager';

import logError from '../../../datahooks/metadata/ErrorLogging.js';

const ProjectNotificationsDialog = ({ projectId }) => {
  // Local state for dialog visibility and feedback messages.
  const [isOpen, setIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch notifications data specific to the project.
  const {
    projectNotificationsData,
    projectNotificationsLoading,
    projectNotificationsError,
  } = useProjectNotifications(projectId);

  // Callbacks for successful or failed notification updates.
  const projectNotificationUpdated = () => {
    setSuccessMessage("Notification updated successfully.");
  };

  const projectNotificationSaveFailed = (error) => {
    setErrorMessage("Failed to update notification: " + (error.message || 'Unknown error'));
  };

  // Hook to manage notification updates using optimistic updates.
  const { performAction } = useProjectNotificationManager(
    projectNotificationUpdated,
    projectNotificationSaveFailed
  );

  // Toggle the dialog open/closed.
  const handleToggle = () => setIsOpen(!isOpen);

  // Handle toggling a notification on or off.
  const handleToggleNotification = (notification) => {
    // Determine the new active status.
    const newStatus = notification.active === 'active' ? 'inactive' : 'active';
    const payload = {
      project_id: projectId,
      notification_id: notification.id,
      active: newStatus,
    };

    // Call performAction from the notification manager hook.
    performAction(payload)
      .catch((error) => {
        logError("Error updating notification:", error);
      });
  };

  if (projectNotificationsLoading) {
    return <LoadingIcon showText />;
  }
  if (projectNotificationsError) {
    return <div>Error: {projectNotificationsError.message}</div>;
  }

  return (
    <>
      <Button
        data-testid="project-notifications-button"
        size="sm"
        variant="outlined"
        className="flex text-blue-500 items-center gap-3"
        onClick={handleToggle}
      >
        <FaBell data-testid="pnb-icon" size={20} /> Notifications
      </Button>

      <Dialog data-testid="project-notifications-dialogue" open={isOpen} size="lg" handler={handleToggle} dismissible={false}>
        <div className="p-4">
          <h3 className="text-lg font-bold" data-testid="pnd-title">Project Notifications</h3>
          {projectNotificationsData && projectNotificationsData.length > 0 ? (
            projectNotificationsData.map((notification) => (
              <div data-testid={`pnd-notification-container${notification.id}`} key={notification.id} className="flex justify-between items-center py-2 border-b">
                <div>
                  <div data-testid="pnd-nc-title" className="font-bold">{notification.name}</div>
                  <div data-testid="pnd-nc-description" className="text-sm text-gray-600">{notification.description}</div>
                </div>
                <Switch data-testid="pnd-nc-switch"
                  checked={notification.active === 'active'}
                  color="green"
                  onChange={() => handleToggleNotification(notification)}
                />
              </div>
            ))
          ) : (
            <div data-testid="pnd-no-notifications">No notifications available.</div>
          )}
          <AlertManager
            data-testid="pnd-alert-manager"
            className="m-2"
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </div>
        <Dialog.Footer data-testid="pnd-footer">
          <Button data-testid="pndf-close-button" color="pink" onClick={handleToggle}>
            Close
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

ProjectNotificationsDialog.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default ProjectNotificationsDialog;