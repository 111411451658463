//Project manager provides a hook that performs different mutations on 
//project data and handles multiple methods for mutating the data.
import useAuthenticatedMutation from '../useAuthenticatedMutation';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_ADMIN_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config';
import logError from '../../metadata/ErrorLogging';

// Function to perform HTTP requests
const manageUser = async ({data, method}, token) => {
    let url = `${API_ADMIN_URL}user`;

    if (method === 'DELETE' && data.del_user_id) {
        url += `?del_user_id=${data.del_user_id}`; // Add to URL for DELETE
    }

    try {
        const response = await axios({
            method: method,
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY,
            },
            data: method !== 'DELETE' ? data : {}, // Only send data for non-DELETE requests
        });

        return response.data;
    } catch (error) {
        logError(`Failed to ${method.toLowerCase()} data: ${error.message}`, error, {});
        throw error.response?.data ?? error;
    }
};

// Hook to manage users
export default function useUserManager(onSuccess, onError) {
    
    const projectScope = [PROJECT_SCOPE];
    const { mutate, isLoading, isError, error, data, reset: resetMutation } = useAuthenticatedMutation(manageUser, projectScope, {
        onSuccess,
        onError
    });

    // Function to call the mutate function with additional parameters for different HTTP methods
    const performAction = (data, method = 'POST') => {
        return new Promise((resolve, reject) => {
            mutate(
                { data, method },
                {
                    onSuccess: (result) => {
                        resolve(result);
                        if (onSuccess) onSuccess(result);
                    },
                    onError: (error) => {
                        // Ensure that we always reject with an Error object
                        const rejectionError = error instanceof Error ? error : new Error(error.message || 'An error occurred');
                        reject(rejectionError);
                        if (onError) onError(rejectionError);
                    },
                }
            );
        });
    }


    return {
        performAction,
        isLoading,
        isError,
        error,
        data,
        reset: resetMutation
    };
}

useUserManager.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}