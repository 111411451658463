import React, { useState, useEffect, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";

import useProjects from "../datahooks/useProjects";

import AuthenticatedLayout from "../layouts/AuthenticatedLayout";

import AvatarLogo from "../components/general/AvatarLogo";
import TextPlaceholder from "../components/general/TextPlaceholder";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import { Select, SelectItem, Card } from "@tremor/react";

import { featureDetails } from "../datahooks/metadata/DataUtils";
import { CARD_TYPES, ErrorCard } from "../components/cards/ErrorCard";
import LoadingIcon from "../components/navigation/LoadingIcon";

import { ProjectProvider } from "../datahooks/ProjectContext";

// Use React.lazy to lazily load these components when required
const OverviewTabPanel = lazy(() =>
  import("../components/cards/overview/OverviewTabPanel")
);
const TestTypeTabPanel = lazy(() =>
  import("../components/cards/TestTypeTabPanel")
);

export default function Project() {
  const [activeTab, setActiveTab] = useState("overview");
  const [releaseId, setReleaseId] = useState("");
  const [project, setProject] = useState(null);

  const { projectId } = useParams();
  const { projects, isLoading: isLoadingProjects } = useProjects();

  useEffect(() => {
    if (!isLoadingProjects) {
      const newProject = projects.find(
        (p) => p.projectId.toString() === projectId
      );

      if (newProject) {
        setProject(newProject);
        if (newProject.releases.length > 0) {
          setReleaseId(newProject.releases[0].release_id.toString());
        } else {
          console.log("No releases found");
          setReleaseId("");
        }
      }
    }
  }, [projects, projectId]);

  const changeTab = (tabName) => {
    setActiveTab(tabName.toString());
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const doChangeRelease = (newVal) => {
    setReleaseId(newVal.toString());
  };

  let tabHeaders = [];
  let tabPanels = [];
  let release = project?.releases.find(
    (r) => r.release_id.toString() === releaseId
  );

  if (project && releaseId) {
    tabHeaders = [
      <Tab
        key="overview"
        value="overview"
        className="z-0"
        onClick={() => {
          changeTab("overview");
        }}
      >
        Overview
      </Tab>,
      ...project.testTypeIds.map((testTypeId) => (
        <Tab
          key={testTypeId}
          value={testTypeId.toString()}
          className="z-0"
          onClick={() => {
            changeTab(testTypeId.toString());
          }}
        >
          {featureDetails[testTypeId].title || `Test Type ${testTypeId}`}
        </Tab>
      )),
    ];

    tabPanels = [
      <TabPanel key="overview" value="overview">
        <Suspense
          fallback={
            <div className="flex items-center justify-center h-full w-full">
              <LoadingIcon size={150} />
            </div>
          }
        >
          <OverviewTabPanel
            releaseId={releaseId}
            title={project?.title}
            intro={project?.intro_text}
            releaseTitle={release?.presentation_name}
            releaseStatus={release?.release_status}
            releasedIntoTest={project?.released_into_test}
            releaseStartDate={release?.creation_date}
            releaseLastDate={release?.last_update}
            targetToLive={project?.target_to_live}
            projectId={project?.projectId}
          />
        </Suspense>
      </TabPanel>,
      ...project.testTypeIds.map((testTypeId) => (
        <TabPanel key={testTypeId} value={testTypeId.toString()}>
          <Suspense
            fallback={
              <div className="flex items-center justify-center h-full w-full">
                <LoadingIcon size={150} />
              </div>
            }
          >
            <TestTypeTabPanel
              projectId={project?.projectId}
              releaseId={releaseId}
              testTypeId={testTypeId}
              key={`${releaseId}_${testTypeId}`}
            />
          </Suspense>
        </TabPanel>
      )),
    ];
  }

  return (
    <AuthenticatedLayout>
      <ProjectProvider projectId={Number(projectId)} doChangeTab={changeTab}>
        <div className="mt-1 p-3 sm:p-5 bg-gray-50 dark:bg-dark-tremor-background-muted rounded-lg border dark:border-dark-tremor-border  mx-auto">
          <div className="grid grid-cols-[auto_1fr_auto] items-center border-b-2 dark:border-dark-tremor-border p-3 mb-2">
            {project?.title ? (
              <>
                <AvatarLogo
                  src={project.logo_location}
                  alt={`${project.short_name || 'Project'} logo`}
                  size="lg"
                  displayType="logo"
                />
                <Typography
                  variant="h2"
                  className="ml-2 md:ml-5 md:mr-5 mr-2 text-blue-500 "
                >
                  {project.title}
                </Typography>
              </>
            ) : (
              <TextPlaceholder lines={1} width="w-1/2" />
            )}
            <div>
              {project && project.releases.length > 0 ? (
                <Select
                  className="mt-Select-button z-22"
                  label="Build"
                  value={releaseId}
                  onChange={(newVal) => doChangeRelease(newVal)}
                >
                  {project.releases.map((release) => (
                    <SelectItem
                      className="mt-Select-option w-full"
                      key={release.version_id}
                      value={release.release_id.toString()}
                    >
                      {release.presentation_name}
                    </SelectItem>
                  ))}
                </Select>
              ) : (
                <TextPlaceholder lines={1} width="w-1/2" />
              )}
            </div>
          </div>

          {project?.releases?.length === 0 ? (
            <Card>
              <ErrorCard cardType={CARD_TYPES.EMPTY} />
              <Typography variant="h3" className="w-full mt-5 text-center">
                No releases found.
              </Typography>
              <Typography variant="paragraph" className="w-full text-center">
                This could be because the project is new or because no releases
                have been made in the past 30 days.
              </Typography>
            </Card>
          ) : (
            <Tabs
              key={`tab-${activeTab}`} // Force re-render on tab change
              data-testid="project-tabs"
              className="dark:bg-dark-tremor-background-muted"
              value={activeTab}
            >
              <TabsHeader
                data-testid="project-tabs-header"
                className="dark:bg-dark-tremor-background-muted"
                indicatorProps={{
                  className: "dark:bg-blue-500",
                }}
              >
                {tabHeaders}
              </TabsHeader>
              <TabsBody data-testid="project-tabs-body">{tabPanels}</TabsBody>
            </Tabs>
          )}
        </div>
      </ProjectProvider>
    </AuthenticatedLayout>
  );
}