import React from 'react';
import LoadingIcon from '../components/navigation/LoadingIcon.jsx';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout.jsx';

import useProjects from '../datahooks/useProjects'; 

import { Card  } from "@tremor/react";
import { Link } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import FeatureIcon from '../components/general/FeatureIcon.jsx';
import AvatarLogo from '../components/general/AvatarLogo.jsx';
import { createSafeMarkup } from '../datahooks/metadata/DataUtils.js';

import { useOrgContext } from '../datahooks/admin/organisation/AdminOrgIDContext';

export default function Home() {
    const { projects, isLoading } = useProjects();  //Load list of projects from useProjects hook
    const { organisationData } = useOrgContext();

    const logoUrl = organisationData?.logo_url ? organisationData.logo_url : "/assets/logo/tiqtoq.png";

    return (
        <AuthenticatedLayout>
            <div className="mt-1 p-0 md:p-6 bg-gray-50 dark:bg-dark-tremor-background-muted rounded-lg border dark:border-dark-tremor-border ">
                <Card className="flex w-full h-full flex-col static shadow-xl">
                    <Typography color='blue' variant='h2' className='hidden md:flex p-2 !normal-case'>disQo Dashboard</Typography>
                    <div className="flex md:flex-row flex-col items-start">
                        <div className="md:order-2 order-1 w-full lg:w-1/6 md:w-1/5 flex-shrink-0 text-center m-3">
                        <AvatarLogo
                            src={logoUrl}
                            alt={`${organisationData.title || 'Organisation'} logo`}
                            size='xl'
                            initialsCount={2}
                            displayType='logo'
                        />
                        </div>
                        <Typography variant='paragraph' className="md:order-1 order-2 flex-grow ml-2">
                            Welcome to the disQo Dashboard, the essential tool for overseeing the quality of your digital products and releases. The platform offers a comprehensive suite of features for in-depth User Interface analysis, robust API monitoring, detailed performance metrics, thorough security audits, and accessibility compliance. Designed for efficiency and ease of use, the disQo Dashboard is your reliable solution in enhancing and securing your digital offerings with precision and expertise.
                        </Typography>
                    </div>
                </Card>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-1 p-6 sm:p-10 bg-gray-50 dark:bg-dark-tremor-background-muted rounded-lg border mx-auto dark:border-dark-tremor-border ">
                {/* Loading State */}
                {isLoading && (
                    <div className="col-span-1 md:col-span-2 lg:col-span-3 flex items-center justify-center h-60">
                        <LoadingIcon size={150} showText={true} />
                    </div>
                )}

                {/* Projects Loaded */}
                {!isLoading && projects.length > 0 && (
                    projects.map((project) => (
                        <Link key={project.short_name} to={`/projects/${project.projectId}`} className="col-span-1 ">
                            <Card data-testid={`home-project`} className="flex flex-col bg-white min-h-50 w-full relative p-2 h-full ">
                                <div className="flex flex-row items-center border-b pb-2 min-h-8 ">
                                    <AvatarLogo 
                                        src={project.logo_location} 
                                        alt={(project.title || 'Project') + " logo"}
                                        displayType="logo"
                                    />
                                    <Typography variant='h2' className="pl-2">{project?.title}</Typography>
                                </div>
                                {project?.intro_text && <Typography variant='paragraph' className="mt-4 mb-4">{createSafeMarkup(project.intro_text)}</Typography>}
                                <div className="flex-grow"></div>
                                <div className="w-full border-t p-3 -mb-4 -mr-4 mt-2 flex justify-end">
                                    {project?.testTypeIds?.map((testTypeId) => (
                                        <FeatureIcon key={`${project.short_name}-${testTypeId}`}  testTypeId={testTypeId} />
                                    ))}
                                </div>
                            </Card>
                        </Link>
                    ))
                )}

                {/* No Projects Available */}
                {!isLoading && projects.length === 0 && (
                    <div className="col-span-1 md:col-span-2 lg:col-span-3 text-center">
                        <Typography variant='paragraph' className="mt-4">No projects available.</Typography>
                    </div>
                )}
            </div>

        </AuthenticatedLayout>
    );
}