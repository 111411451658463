import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import logError from '../../metadata/ErrorLogging.js';

/**
 * @typedef {Object} UserNotification (from the notifications table)
 * @property {number} notification_id - Unique identifier for the notification
 * @property {string} name - The name of the notification.
 * @property {string} description - A description of what the notification does.
 * @property {Array<{ type: string, delivery: string }>} available_channels - The channels available for this notification.
 * @property {string} default_channel - The default channel (e.g., "email").
 * 
 * Subscription fields (from notification_subscriptions); may all be null if no subscription exists:
 * 
 * @property {number|null} project_id - The project ID for the subscription.
 * @property {number|null} user_id - The user ID for the subscription.
 * @property {string|null} channel - The subscription channel.
 * @property {string|null} active - Subscription active status ("active" or "inactive").
 * @property {Object|null} config - JSON configuration object for the subscription.
 * @property {number|null} threshold_value - The threshold value for the subscription.
 */

/**
 * @typedef {Object} UserNotificationsHookReturn
 * @property {UserNotification[]} userNotificationsData - Array of user notifications.
 * @property {boolean} userNotificationsLoading - Flag indicating if the data is currently loading.
 * @property {Function} refetchUserNotifications - Function to refetch the notifications.
 * @property {Error|null} userNotificationsError - Any error encountered during the fetch.
 */

/**
 * Hook to fetch user notifications.
 *
 * @param {number|null} projectId - The ID of the project.
 * @returns {UserNotificationsHookReturn} An object containing the notifications data, loading state, refetch function, and any error.
 */
export default function useUserNotifications(projectId = null, activate = false) {
  
  /**
   * Fetches user notifications.
   *
   * @param {string} token - The authentication token.
   * @returns {Promise<UserNotification[]>}
   */
  const fetchUserNotifications = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/user_notifications?project_id=${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      logError('Failed to load user notifications in useUserNotifications', error, {});
      throw error;
    }
  };
  const queryKey = ['user_notifications', projectId];
  const {
    data: userNotificationsData = [],
    isLoading,
    refetch,
    error
  } = useAuthenticatedQuery(queryKey, fetchUserNotifications, [PROJECT_SCOPE], {
    staleTime: 60 * 60 * 1000, // Stale time of 60 minutes
    enabled: activate && !!projectId // Only fetch if projectId is provided
  });

  return { 
    userNotificationsData, 
    userNotificationsLoading: isLoading, 
    refetchUserNotifications: refetch, 
    userNotificationsError: error 
  };
}

useUserNotifications.propTypes = {
  projectId: PropTypes.number,
  activate: PropTypes.bool
};