import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-tailwind/react';
import { IMAGES_URL, PRIVATE_IMAGES } from '../../config';
import { useSasToken } from '../../datahooks/admin/imageUploadHooks';

/**
 * Renders an avatar logo component.
 *
 * @component
 * @param {string} props.src - The source URL of the image, relative to teh image base URL.
 * @param {string} props.alt - The alternative/tooltip text for the image.
 * @param {string} [props.size='md'] - The size of the avatar logo. Possible values: 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'.
 * @param {number} [props.initialsCount=1] - The number of initials to display in the placeholder, from the alt text.
 * @param {string} [props.displayType='avatar'] - The type of display. Possible values: 'avatar', 'logo'.
 * @param {boolean} [props.withBorder=false] - Whether to display a border around the avatar.
 * @returns {JSX.Element|null} The rendered AvatarLogo component.
 */
const AvatarLogo = ({ src, alt, size = 'md', initialsCount = 1, displayType = 'avatar', withBorder = false }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [hasError, setHasError] = useState(false);
  const { sasToken, isLoading: isTokenLoading, error: isTokenError, triggerFetchToken } = useSasToken('read');

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        if (!src) return;
        // The source is a blob when we've selected an image from the file input and are showing a preview
        // If the source starts /, it is a local asset from the project source
        const isBlobUrl = src?.startsWith('/') || src?.startsWith('blob:');

        if (isBlobUrl) {
          setImageUrl(src);
          return;
        }

        const isFullUrl = src.startsWith('http');
        const formattedSrc = src.startsWith('/') ? src.slice(1) : src;
        const finalSrc = isFullUrl ? src : `${IMAGES_URL}${formattedSrc}`;
       
        if (PRIVATE_IMAGES) {   //Storage container is set to private so we need to fetch a SAS token

          if (!sasToken) {  //Get a SAS token if one is not already available in cache
            if (!isTokenLoading && !isTokenError) {
              triggerFetchToken();
            }
            return; // Wait for token to load before setting the image URL
          }
          const concatCharacter = finalSrc.includes('?') ? '&' : '?';
          setImageUrl(`${finalSrc}${concatCharacter}${sasToken}`);
        } else {    //Storage container is public so we can use the regular URL
          setImageUrl(finalSrc);
        }
      } catch (err) {
        console.error('Error fetching image URL:', err);
        setHasError(true);
      }
    };

    if (src && src !== 'null') {
      fetchImageUrl();
    }
  }, [src, sasToken]);

  const extractInitials = (text, count) =>
    text
      .split(' ')
      .slice(0, count)
      .map(word => word[0]?.toUpperCase())
      .join('') || 'X';

  // Set the placeholder text
  const placeholder = alt ? extractInitials(alt, initialsCount) : 'X';
  const shouldDisplayPlaceholder = hasError || !imageUrl;

  const avatarSizeClasses = {
    xs: 'w-6 h-6 text-xs',
    sm: 'w-8 h-8 text-sm',
    md: 'w-10 h-10 text-base',
    lg: 'w-12 h-12 text-lg',
    xl: 'w-14 h-14 text-xl',
    xxl: 'w-16 h-16 text-2xl',
  };

  const imageSizeClasses = {
    xs: 'h-8 text-xs',
    sm: 'h-12 text-sm',
    md: 'h-14 text-base',
    lg: 'h-16 text-lg',
    xl: 'h-20 text-xl',
    xxl: 'h-24 text-2xl',
  };

  const avatarAppliedSizeClass = avatarSizeClasses[size] || avatarSizeClasses.md;
  const appliedSizeClass = imageSizeClasses[size] || imageSizeClasses.md;

  if (shouldDisplayPlaceholder) {
    return (
      <div className={`flex flex-shrink-0 justify-center items-center rounded-full bg-gray-500 text-white ${avatarAppliedSizeClass}`}>
        {placeholder}
      </div>
    );
  }

  return displayType === 'avatar' ? (
    <Avatar
      src={imageUrl}
      alt={alt}
      size={size}
      onError={() => setHasError(true)}
      withBorder={withBorder}
      color="blue"
      className="p-0.5"
    />
  ) : (
    <img src={imageUrl} alt={alt} className={`object-contain ${appliedSizeClass}`} />
  );
};

AvatarLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  initialsCount: PropTypes.number,
  displayType: PropTypes.oneOf(['avatar', 'logo']),
  withBorder: PropTypes.bool,
};

export default AvatarLogo;