import { useAuthenticatedQuery } from '../../useAuthenticatedQuery.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE } from '../../../config.js';
import logError from '../../metadata/ErrorLogging.js';

/**
 * @typedef {Object} ProjectNotifications
 * @property {number} id - Unique identifier for the notification.
 * @property {string} name - The name of the notification.
 * @property {string} description - A description of what the notification does.
 * @property {string} active - Either "active" or "inactive" indicating the status.
 * @property {Array<{ type: string, delivery: string }>} available_channels - The channels available for this notification.
 * @property {string} default_channel - The default channel (e.g., "email").
 * @property {Object} channel_templates - Templates for various channels.
 */

/**
 * @typedef {Object} ProjectNotificationsHookReturn
 * @property {ProjectNotifications[]} projectNotificationsData - Array of notifications.
 * @property {boolean} projectNotificationsLoading - Flag indicating if the data is currently loading.
 * @property {Function} refetchProjectNotifications - Function to refetch the notifications.
 * @property {Error|null} projectNotificationsError - Any error encountered during the fetch.
 */

/**
 * Hook to fetch project notifications.
 *
 * @param {number|null} projectId - The ID of the project.
 * @returns {ProjectNotificationsHookReturn} An object containing the notifications data, loading state, refetch function, and any error.
 */
export default function useProjectNotifications(projectId = null) {

    /**
     * Fetches project notifications.
     * 
     * @param {string} token - The authentication token.
     * @returns {Promise<ProjectNotifications[]>}
    */
  const fetchProjectNotifications = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}disqoadmin/project_notifications?project_id=${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-functions-key': FUNCTION_KEY
        }
      });
      return response.data;
    } catch (error) {
      logError('Failed to load project notifications in useProjectNotifications', error, {}); // Error during fetch
      throw error;
    }
  };
  
  const { 
        data: projectNotificationsData = [], 
        isLoading, refetch, error } = useAuthenticatedQuery(`project_notifications_${projectId}`, fetchProjectNotifications, [PROJECT_SCOPE], {
    staleTime: 300000, //Stale time of 5 minutes
  });

  return { projectNotificationsData, projectNotificationsLoading: isLoading, refetchProjectNotifications: refetch, projectNotificationsError: error };
}

useProjectNotifications.propTypes = {
  projectId: PropTypes.number
}