import { IMAGE_UPLOAD } from '../../config';

/**
 * Supported image entity types.
 * 
 * Potentially move to shared library when it is available.
 * 
 * @constant
 * @type {Object.<string, string>}
 */
export const IMAGE_ENTITY_TYPES = {
    user: 'user',
    project: 'proj',
    organisation: 'org'
};

/**
 * Supported file signatures for validation.
 * @constant
 * @type {Object.<string, {signature: number[], extension: string}>}
 */
export const SUPPORTED_FILE_SIGNATURES = {
    'image/jpeg': { signature: [0xFF, 0xD8, 0xFF], extension: 'jpg' },
    'image/png': { signature: [0x89, 0x50, 0x4E, 0x47], extension: 'png' },
    'image/gif': { signature: [0x47, 0x49, 0x46, 0x38], extension: 'gif' },
    'image/webp': { signature: [0x52, 0x49, 0x46, 0x46], extension: 'webp' }
};

/**
 * Custom error class for image validation errors.
 */
export class ImageValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ImageValidationError';
    }
}

/**
 * Validates an uploaded image file.
 *
 * @async
 * @param {File} file - The file object to validate.
 * @param {{MAX_SIZE: number, ALLOWED_TYPES: string[]}} [config=IMAGE_UPLOAD] - Configuration object for max file size and allowed types.
 * @throws {ImageValidationError} Throws an error if the file is invalid.
 * @returns {Promise<boolean>} Resolves to `true` if the file passes validation.
 */
export const validateImage = async (file, config = IMAGE_UPLOAD) => {
    if (!file) {
        throw new ImageValidationError('No file provided');
    }

    // Size check
    if (file.size > config.MAX_SIZE) {
        throw new ImageValidationError(
            `File size ${(file.size / 1024).toFixed(1)}KB exceeds maximum allowed size of ${(config.MAX_SIZE / 1024).toFixed(1)}KB`
        );
    }

    // Type check
    if (!config.ALLOWED_TYPES.includes(file.type)) {
        throw new ImageValidationError(
            `File type ${file.type} is not allowed. Allowed types: ${config.ALLOWED_TYPES.join(', ')}`
        );
    }

    // Signature validation for supported types
    if (file.type in SUPPORTED_FILE_SIGNATURES) {
        const signature = SUPPORTED_FILE_SIGNATURES[file.type].signature;
        const fileArrayBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(fileArrayBuffer);
        const header = uint8Array.subarray(0, signature.length);

        const isValid = signature.every((byte, i) => header[i] === byte);
        if (!isValid) {
            throw new ImageValidationError('File content does not match its claimed type');
        }
    }

    return true;
};