import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE, IMAGES_URL, IMAGE_UPLOAD } from '../../config';
import { useAuthenticatedQuery } from '../useAuthenticatedQuery';
import { BlobServiceClient } from '@azure/storage-blob';
import logError from '../metadata/ErrorLogging';
import { ImageValidationError, validateImage } from '../metadata/ImageValidation';

const useUploadImage = (onSavePath) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const mutation = useMutation(async ({ file, sasToken, type, id }) => {  //type i.e. org, user and accompanying id
        try {
            // Validate file before proceeding - enforce max file size and allowed types
            await validateImage(file);
            
            const fileExtension = file.type.split('/').pop();
            const filename = `${type}_${id}.${fileExtension}`;
            const containerUrl = `${IMAGES_URL.replace(/\/$/, '')}?${sasToken}`;
    
            const blobServiceClient = new BlobServiceClient(containerUrl);
            const containerClient = blobServiceClient.getContainerClient('');
            const blobClient = containerClient.getBlockBlobClient(filename);

            const arrayBuffer = await file.arrayBuffer();

            // SVGs should not make it this far, but set content disposition so they are downloadable only just in case
            const contentDisposition = file.type === 'image/svg+xml' 
                ? `attachment; filename="${encodeURIComponent(filename)}"`
                : `inline; filename="${encodeURIComponent(filename)}"`;

            await blobClient.uploadData(arrayBuffer, {
                blobHTTPHeaders: { 
                    blobContentType: file.type,
                    blobContentDisposition: contentDisposition
                },
                onProgress: (progress) => {
                    setUploadProgress((progress.loadedBytes / progress.totalBytes) * 100);
                }
            });
    
            return filename;
        } catch (error) {
            // Handle validation errors separately to provide better user feedback
            if (error instanceof ImageValidationError) {
                logError('Image validation failed', error, {
                    fileType: file?.type,
                    fileName: file?.name,
                    fileSize: file?.size
                });
                throw error; // Rethrow to let React Query handle it
            }

            // Handle other errors
            logError('Error in upload mutation', error, {
                fileType: file?.type,
                fileName: file?.name,
                fileSize: file?.size
            });
            throw error;
        }
    }, {
        onError: (error) => {
            logError('Error uploading image', error, {});
        },
        onSuccess: (filename) => {
            const filenameWithTimestamp = `${filename}?${Date.now()}`;
            onSavePath(filenameWithTimestamp); 
        }
    });

    return { 
        ...mutation, 
        uploadProgress,
        maxFileSize: IMAGE_UPLOAD.MAX_SIZE,           // Expose config values for UI
        allowedTypes: IMAGE_UPLOAD.ALLOWED_TYPES
    };
};

const useSasToken = (returnType = 'read') => {

    if (!["read","upload"].includes(returnType)) {
        throw new Error('Invalid token type. Use "read" or "upload"');
    }

    const [triggerFetch, setTriggerFetch] = useState(false);

    //Expiry times in seconds, set to match the image_token API settings
    const EXPIRY_TIMES = {
        read: 3600, // 1 hour
        upload: 300, // 5 minutes
    };

    const fetchSasToken = async (token) => {
        const url = `${API_BASE_URL}disqoadmin/image_token?returnType=${returnType}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY
            }
        });
        if (!response.ok) {
            logError('Failed to fetch SAS token', response, {});
            throw new Error(`Failed to fetch SAS token, status ${response.status}`);
        }
        
        const data = await response.json();
        return data.sasToken;
    };

    const projectScope = [PROJECT_SCOPE];
    const { data: sasToken, isLoading, error } = useAuthenticatedQuery(
        ['sasToken', returnType], //Use the return type in the query key to separate read and upload tokens in the cache 
        fetchSasToken, 
        projectScope,
        {
            enabled: triggerFetch,  // Triggered manually
            onSuccess: () => setTriggerFetch(false),  // Reset trigger after successful fetch
            staleTime: (EXPIRY_TIMES[returnType] * 0.8) * 1000,  // Set time before refresh to 80% of expiry
            cacheTime: EXPIRY_TIMES[returnType] * 1000,  // Set usable token time to expiry
        }
    );

    const triggerFetchToken = () => {
        setTriggerFetch(true);
    };

    return { sasToken, isLoading, error, triggerFetchToken };
};

export { useUploadImage, useSasToken };

useUploadImage.propTypes = {
    onSavePath: PropTypes.func
};